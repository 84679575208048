import { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Container, Grid, Paper, TextField } from "@mui/material";
import Broadcast from "./broadcast";
import LogTable from "./table";

const stylePadding = { mt: 2, mb: 2 };

function createCountData(name, source, count) {
    return [name, source, count];
}

export default function Admin() {
    const [token, setToken] = useState("");
    const [counters, setCounters] = useState([]);
    const [listeners, setListeners] = useState([]);
    const [log, setLog] = useState({});

    const headers = {
        counters: ["Counters", "Source", "Count"],
        listeners: ["Listener Counters", "Count"],
    }

    const onGetLog = () => {
        if (token.length > 0) {
            axios.get(`http://chatlink.homester.hk/admin?token=${token}`)
                .then(response => {
                    if (response) {
                        setLog(response.data);
                    } else {
                        console.error("Response not found!");
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    const handleTokenChange = (e) => {
        setToken(e.target.value);
    }

    useEffect(() => {
        const newCounters = [
            createCountData("Number of client connections", "service/io", log.connectionCount),
            createCountData("Number of emails sent", "service/email", log.emitEmailCount),
            createCountData("Number of emitted socket responses", "service/io", log.emitResponseTotalCount),
            createCountData("Number of emitted socket responses where error exists", "service/io", log.errorResponseCount),
            createCountData("Wrong cache attempts", "service/cache", log.errorCacheAttemptCount)

        ];

        if (log.emitResponseCounts) {
            const newListeners = [];
            for (let [key, value] of Object.entries(log.emitResponseCounts)) {
                newListeners.push([key, value]);
            }
            setListeners(newListeners);
        }

        setCounters(newCounters);
    }, [log]);

    return (
        <Container>
            <Box sx={{ p: 4 }}>
                <Paper elevation={1}>
                    <Grid container spacing={2} sx={{ p: 4 }}>
                        <Grid item xs={9}>
                            <Paper>
                                <LogTable header={headers.counters} data={counters} />
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>

                            <Paper>
                                <Box sx={{ p: 2 }}>
                                    <TextField id="standard-basic" label="Admin token" variant="standard"
                                        value={token} onChange={handleTokenChange} />
                                    <br />
                                    <Button variant="contained" onClick={onGetLog}>Get Log</Button>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={9}>
                            <Paper>
                                <LogTable header={headers.listeners} data={listeners} />
                            </Paper>
                        </Grid>
                        <Grid item xs={9}>
                            <Broadcast />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    )
}
