import { useState } from "react";
import axios from "axios";
import { Box, Button, Paper, TextareaAutosize, TextField, Typography } from "@mui/material";

export default function Broadcast() {
    const [token, setToken] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const handleTokenChange = e => setToken(e.target.value);
    const handleTitleChange = e => setTitle(e.target.value);
    const handleContentChange = e => setContent(e.target.value);

    const onPostBroadcastMessage = async (e) => {
        if (token.length === 0) {
            console.error("Pushing broadcast message must have an user token!");
            return;
        }

        if (title.length > 0 && content.length > 0) {
            const data = {
                token,
                type: "text",
                title,
                body: content,
            };

            try {
                const response = await axios.post(`http://chatlink.homester.hk/admin/broadcast`, data);
                if (response) {
                    console.log(response.data);
                } else {
                    console.error("Response not found!");
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.error("Cannot post broadcast message with empty title or content!");
        }
    };

    return (
        <Paper>
            <Box sx={{ p: 2 }}>
                <Typography variant="h5">Broadcasts</Typography>
                <TextField id="admin-broadcast-token" label="User Token" variant="standard"
                    value={token} onChange={handleTokenChange} style={{ width: 600 }}
                />
                <TextField id="admin-broadcast-title" label="Broadcast Title" variant="standard"
                    value={title} onChange={handleTitleChange} style={{ width: 600 }}
                />
                <br />
                <TextareaAutosize
                    aria-label="broadcast-content"
                    placeholder="Enter broadcast message (plaintext) here..."
                    style={{
                        width: 600,
                        height: 200,
                    }}
                    onChange={handleContentChange}
                />
                <Button variant="contained" onClick={onPostBroadcastMessage}>Post Message</Button>
            </Box>
        </Paper>
    );
}
