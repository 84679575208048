import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import Sidebar from "./sidebar";
import Mainbar from "./mainbar";
import ChatroomTopProfile from "./profile/chatroomTopProfile";
import styles from "./chatroom.styles";
import { pushMessageToChannel, updateChatList } from "./chatroom.utils";
import {
  doGetChatList,
  doGetChatHistory,
  doGetUserProfile,
  doResetUnseenCount,
  doPostMessage,
  doPostInvitation,
  doPostUserLogin,
  doReplyInvitation,
} from "../api/chat";

import { postLogin, postLoginWithCookie } from "../api/auth";
import { connectWebSocket } from "../api/websocket.js";
import { onPostMessage, onReplyInvitation } from "../api/chat.model";
import { onUserPresenceChanged } from "../api/auth.model";

export default function Chatroom(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [token, setToken] = useState("no-token");

  const [chatList, setChatList] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [lastChatMessageReceived, setLastChatMessageReceived] = useState(null);
  const [channelStatusUpdate, setChannelStatusUpdate] = useState({});
  const [authStatus, setAuthStatus] = useState(true);

  const [currentChannel, setCurrentChannel] = useState(-1);
  const [websocket, setWs] = useState(null);

  const [myProfile, setMyProfile] = useState({
    uid: -1,
    profileName: "default",
    imageKey: null,
    gender: "F",
  });

  const [topProfile, setTopProfile] = useState({
    profiles: [
      {
        profileName: "default",
        uid: -1,
        channel: -1,
        channelType: "private",
        presence: false,
        imageKey: null,
        gender: "F",
      },
    ],
    channelInfo: {
      channel: -1,
      channelType: "private",
    },
  });

  const onGetChatroomHistory = async (token, channel) => {
    const history = await doGetChatHistory(token, channel);

    if (history.memberInfoList && history.memberInfoList.length > 0) {
      const { messages, channelInfo, memberInfoList } = history;

      let profiles;

      switch (channelInfo.channelType) {
        case "private":
          profiles = [{ ...memberInfoList[0] }];
          break;
        case "group":
          profiles = [...memberInfoList];
          break;
        default:
          return;
      }

      if (channel === -1 || channel === channelInfo.channel) {
        setChatMessages(messages);
        setTopProfile({
          profiles,
          channelInfo,
        });
        setChannelStatusUpdate({
          channel: channelInfo.channel,
          presence: profiles.presence,
          lastOnline: "",
        });
      }
    }
  };

  const onGetChatroomMyProfile = async (token) => {
    const profile = await doGetUserProfile(token);
    setMyProfile(profile);
  };

  const onGetChatroomList = async (token) => {
    const chatList = await doGetChatList(token);

    setChatList(
      chatList.channelInfoList.sort((a, b) => {
        const aDate = new Date(a.channelInfo.lastMessage.datetime);
        const bDate = new Date(b.channelInfo.lastMessage.datetime);
        if (aDate > bDate) {
          return -1;
        } else if (aDate < bDate) {
          return 1;
        } else return 0;
      })
    );

    if (chatList.channelInfoList.length > 0 && currentChannel === -1) {
      const channel = chatList.channelInfoList[0].channelInfo.channel;
      setCurrentChannel(channel);
      if (channel !== -1) {
        await onGetChatroomHistory(token, channel);
      }
    }
  };

  const onResetUnseenCount = async (token, channel) => {
    const response = await doResetUnseenCount(token, channel);

    if (response.success) {
      updateChatListItem(channel, { unseenCount: 0 }, {});
    } else {
      console.error(
        `Cannot reset unseen count on channel: ${response.channel}`
      );
    }
  };

  useEffect(() => {
    const cookieToken = Cookies.get("token");

    if (cookieToken != null && token === "no-token") {
      setToken(cookieToken);
    } else {
      console.log(cookieToken, token);
    }
  }, []);

  useEffect(() => {
    if (websocket) {
      let listeners = [
        {
          name: "auth.userPresenceChanged:receive",
          method: onUserPresenceChanged,
          callback: setChannelStatusUpdate,
        },
        {
          name: "chat.postMessage:receive",
          method: onPostMessage,
          callback: setLastChatMessageReceived,
        },
        {
          name: "chat.replyInvitation:receive",
          method: onReplyInvitation,
          callback: setLastChatMessageReceived,
        },
      ];

      for (let listener of listeners) {
        if (listener.callback) {
          websocket.on(listener.name, (res) =>
            listener.method(res, listener.callback)
          );
        } else {
          websocket.on(listener.name, (res) => listener.method(res));
        }
      }
    }
  }, [websocket]);

  useEffect(() => {
    if (lastChatMessageReceived) {
      const { message } = lastChatMessageReceived;
      if (!lastChatMessageReceived.error.errorExist) {
        const newCurrentMessages = pushMessageToChannel(
          chatMessages,
          lastChatMessageReceived,
          currentChannel
        );
        setChatMessages(newCurrentMessages);

        if (
          currentChannel !== message.channel &&
          myProfile.uid !== message.uid
        ) {
          // add unseen count only if message is sent from others, and not current room
          updateChatListItem(message.channel, { unseenCount: -1 }, {});
        }

        updateChatLastMessage(message.channel, message.text);
      } else if (myProfile.uid === message.uid) {
        alert(lastChatMessageReceived.error.errorMessage);
      }
    }
  }, [lastChatMessageReceived]);

  useEffect(() => {
    if (channelStatusUpdate.uid !== myProfile.uid) {
      const { channel, presence, lastOnline, unseenCount } =
        channelStatusUpdate;

      if (presence != null || lastOnline != null) {
        updateChatListItem(
          channel,
          {},
          {
            presence,
            lastOnline,
          }
        );
      }

      if (unseenCount != null) {
        updateChatListItem(channel, { unseenCount }, {});
      }

      if (currentChannel === channel) {
        let newTopProfile = { ...topProfile };
        newTopProfile.profiles.presence = presence;

        setTopProfile(newTopProfile);
      }
    }
  }, [channelStatusUpdate]);

  useEffect(() => {
    if (authStatus === true) {
      console.log("Test a2");
      sendAutoLoginRequest();
    }
  }, [authStatus]);

  const updateChatListItem = (channel, channelProperties, userProperties) => {
    const newChatList = updateChatList(
      chatList,
      channel,
      channelProperties,
      userProperties
    );
    if (newChatList.length > 0) {
      setChatList(
        newChatList.sort((a, b) => {
          const aDate = new Date(a.channelInfo.lastMessage.datetime);
          const bDate = new Date(b.channelInfo.lastMessage.datetime);
          if (aDate > bDate) {
            return -1;
          } else if (aDate < bDate) {
            return 1;
          } else return 0;
        })
      );
    }
  };

  const updateChatLastMessage = (channel, text) => {
    const trimmedText = text.length > 20 ? text.substring(0, 20) + "..." : text;
    updateChatListItem(channel, { lastMessage: trimmedText }, {});
  };

  const onClickChatListItem = async (channel) => {
    setCurrentChannel(channel);

    await Promise.all([
      onGetChatroomHistory(token, channel),
      onResetUnseenCount(token, channel),
    ]);
  };

  const sendLoginRequest = async (username, password) => {
    const { content } = await postLogin(username, password);
    setToken(content.token);

    if (websocket) {
      doPostUserLogin(websocket, content.token);
    } else {
      connectWebSocket(setWs);
    }

    await Promise.all([
      onGetChatroomMyProfile(content.token),
      onGetChatroomList(content.token),
    ]);
  };

  const sendAutoLoginRequest = async () => {
    const { content } = await postLoginWithCookie();
    setToken(content.token);
    setAuthStatus(false);

    if (websocket) {
      doPostUserLogin(websocket, content.token);
    } else {
      connectWebSocket(setWs);
    }

    await Promise.all([
      onGetChatroomMyProfile(content.token),
      onGetChatroomList(content.token),
    ]);
  };

  const sendChatMessage = (text) => {
    doPostMessage(websocket, token, currentChannel, text);
    updateChatListItem(currentChannel, { unseenCount: 0 }, {});
    console.log("I send a message now!");
    //when send a message, refetch the channel chat list
    onGetChatroomHistory(token, currentChannel);
    onGetChatroomList(token);
  };

  const sendInvitation = () => {
    doPostInvitation(websocket, token, currentChannel);
  };

  const sendReplyInvitation = (accept, messageId) => {
    doReplyInvitation(websocket, token, currentChannel, accept, messageId);
  };

  const DrawerSidebar = (props) => (
    <Sidebar
      collapse={props.collapse}
      currentChannel={currentChannel}
      chatList={chatList}
      myProfile={myProfile}
      onClickChatListItem={onClickChatListItem}
      sendLoginRequest={sendLoginRequest}
    />
  );

  return (
    <div id="main-container" className="default">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" sx={styles.appbar}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: "none" },
              }}
            >
              <MenuIcon sx={{ backgroundColor: "#3C5666" }} />
            </IconButton>
            <ChatroomTopProfile
              token={token}
              info={topProfile}
              onPostInvitation={sendInvitation}
            />
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={styles.navBox}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={styles.temporaryDrawer}
          >
            <DrawerSidebar collapse={true} />
          </Drawer>
          <Drawer variant="permanent" sx={styles.permanentDrawer} open>
            <DrawerSidebar collapse={false} />
          </Drawer>
        </Box>
        <Box sx={styles.mainbar}>
          <Mainbar
            currentChannel={currentChannel}
            chatMessages={chatMessages}
            myProfile={myProfile}
            objectProfile={topProfile.profiles[0]}
            onReplyInvitation={sendReplyInvitation}
            sendChatMessage={sendChatMessage}
          />
        </Box>
      </Box>
    </div>
  );
}
