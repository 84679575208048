import { Button, ButtonGroup, Typography } from "@mui/material";

export default function ChatroomMessagePrivate(props) {
  // This is a single message displayed in a chatroom
  const { message, myProfile, objectProfile } = props;
  const messageId = `chat-msg-${message.id}`;

  let orientation = "";
  if (message.uid > 0 && myProfile.uid > 0) {
    orientation = message.uid === myProfile.uid ? "RIGHT" : "LEFT";
  } else {
    console.error("Message position not found, id: ", message.uid);
  }

  let profilePath;
  let profileIconPath;

  let text = message.text;

  switch (orientation) {
    case "LEFT":
      profilePath = `https://homester.hk/personal-info.php?id=${message.uid}`;
      //profileIconPath = `https://homester.hk/assets/img/img.php?t=uicon&filename=null&gender=F`;
      profileIconPath = `https://homester.hk/assets/img/img.php?t=uicon&filename=${objectProfile?.imageKey}&gender=${objectProfile?.gender}`;

      switch (message.type) {
        case "invitation":
          text = "You are invited for pairing roommate.";
          break;
        case "invitationReply":
          text = "You are invited for pairing roommate.";
          break;
        case "invitationResult":
          text = `You have ${text} the invitation.`;
          break;
        case "stfp":
          text = JSON.parse(message.text)["message"];
          break;
      }
      break;
    case "RIGHT":
      profilePath = `https://homester.hk/personal-info.php?id=${myProfile.uid}`;
      profileIconPath = `https://homester.hk/assets/img/img.php?t=uicon&filename=${myProfile.imageKey}&gender=${myProfile.gender}`;

      switch (message.type) {
        case "invitation":
          text = "You have sent invitation for pairing roommate.";
          break;
        case "invitationReply":
          text = "You are invited for pairing roommate.";
          break;
        case "invitationResult":
          text = `You have ${text} the invitation.`;
          break;
        case "stfp":
          text = JSON.parse(message.text)["message"];
          break;
      }
      break;
  }

  const datetime = new Date(message.datetime).toLocaleTimeString();

  const invitationIsValid = (type, text) => {
    return (
      type === "invitation" && !(text === "accepted" || text === "rejected")
    );
  };

  function InvitationReplyButtons(props) {
    return (
      <ButtonGroup
        size="small"
        variant="outlined"
        aria-label="small button group"
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => props.onSubmit(true, props.messageId)}
        >
          Accept
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => props.onSubmit(false, props.messageId)}
        >
          Reject
        </Button>
      </ButtonGroup>
    );
  }

  function LeftMessage(props) {
    return (
      <div id={messageId} className="media d-flex mb-4 msg-L">
        <div className="mr-4 thumb-img">
          <a href={props.profileLink} target="_blank">
            <img
              src={props.profileIconLink}
              alt=""
              className="img-fluid rounded-circle user-icon"
              width="50"
            />
          </a>
          <br />
          <Typography variant="caption">{props.datetime}</Typography>
        </div>
        <div className="p-3 mr-auto speech-bubble alt">
          <Typography variant="body2">{text}</Typography>
          {invitationIsValid(message.type, message.text) && (
            <InvitationReplyButtons onSubmit={props.onReplyInvitation} />
          )}
        </div>
      </div>
    );
  }

  function RightMessage(props) {
    return (
      <div id={messageId} className="media d-flex mb-4 msg-R">
        <div className="p-3 ml-auto speech-bubble">
          <Typography variant="body2">{text}</Typography>
        </div>
        <div className="ml-4">
          <a href={props.profileLink} target="_blank">
            <img
              src={props.profileIconLink}
              alt=""
              className="img-fluid rounded-circle user-icon"
              width="50"
            />
          </a>
          <br />
          <Typography variant="caption">{props.datetime}</Typography>
        </div>
      </div>
    );
  }

  if (orientation === "RIGHT") {
    return (
      <RightMessage
        text={message.text}
        profileLink={profilePath}
        profileIconLink={profileIconPath}
        datetime={datetime}
      />
    );
  } else if (orientation === "LEFT") {
    return (
      <LeftMessage
        text={message.text}
        messageId={message.id}
        profileLink={profilePath}
        profileIconLink={profileIconPath}
        datetime={datetime}
        onReplyInvitation={props.onReplyInvitation}
      />
    );
  } else {
    return <></>;
  }
}
