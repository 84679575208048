import { useState } from "react";
import {
    Button,
    Divider,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Tooltip,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ProfileMemberList from "./profileMemberList";

import {
    getGroupPairStatus,
    joinGroupPairing,
    withdrawGroupPairing,
    postCanConfirmGroupPairing,
    postConfirmGroupPairing,
} from "../../api/groupPairing";

export default function InvitationGroupDialog(props) {
    const [open, setOpen] = useState(false);
    const [canPair, setCanPair] = useState(true);
    const [canJoin, setCanJoin] = useState(true);
    const [canConfirm, setCanConfirm] = useState(false);
    const [groupStatus, setGroupStatus] = useState([]);

    const getImageLink = (imageKey, gender) => `https://homester.hk/assets/img/img.php?t=uicon&filename=${imageKey}&gender=${gender}`;

    const onGetGroupPairStatus = async () => {
        try {
            const {
                success,
                profiles,
                requestUserJoined,
                groupIsPaired,
            } = await getGroupPairStatus(props.token, props.channel);

            if (success) {
                const newGroupStatus = profiles.map(profile => (
                    {
                        profileName: profile.profileName,
                        imageLink: getImageLink(profile.imageKey, profile.gender),
                        caption: groupIsPaired ? "paired to the same group" : profile.joined ? "joined" : "not joined",
                        role: "member/host",
                    }
                ));

                setGroupStatus(newGroupStatus);
                if (groupIsPaired) {
                    setCanPair(false);
                    setCanJoin(false);
                    setCanConfirm(false);
                } else {
                    setCanJoin(!requestUserJoined);
                }
            }

        } catch (e) {
            console.error(e);
        }
    }

    const onJoinGroupPairing = async () => {
        try {
            const { success, status } = await joinGroupPairing(props.token, props.channel);
            if (success) {
                await onGetGroupPairStatus();
                setCanJoin(!status.joined);
                console.log(status);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const onWithdrawGroupPairing = async () => {
        try {
            const { success, status } = await withdrawGroupPairing(props.token, props.channel);
            if (success) {
                setCanJoin(status.withdrawn);
                await onGetGroupPairStatus();
            }
        } catch (e) {
            console.error(e);
        }
    }

    const onPostCanConfirmGroupPairing = async () => {
        try {
            const { success, status } = await postCanConfirmGroupPairing(props.token, props.channel);
            if (success) {
                setCanConfirm(status.canConfirm);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const onPostConfirmGroupPairing = async () => {
        try {
            const { success, status } = await postConfirmGroupPairing(props.token, props.channel);
            if (success) {
                setCanConfirm(status.canConfirm);
                await onGetGroupPairStatus();
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleClickOpen = () => {
        onGetGroupPairStatus();
        onPostCanConfirmGroupPairing();
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const inlineBlockStyle = { display: "inline-block" };

    return (
        <div>
            <Tooltip title="Pair roommate">
                <a href="#" onClick={handleClickOpen}>
                    <PersonAddAlt1Icon />
                </a>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {"Pair Roommate (Group)"}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>
                        To pair all members in the group as roommate, follow these steps:
                        <br />
                        1. Each member should click "join" to be ready for the group pairing.
                        <br />
                        2. Once all members are in "ready" state, the host of the group should click "confirm" and then
                        the pairing is done!
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <ProfileMemberList memberInfoList={groupStatus} />

                <DialogActions>
                    <div style={inlineBlockStyle}>
                        <Button onClick={handleClose}>Cancel</Button>
                    </div>
                    {canPair &&
                        (<div style={inlineBlockStyle}>
                            {canJoin &&
                                <Button variant="contained" onClick={onJoinGroupPairing} autoFocus>
                                    Join
                                </Button>
                            }
                            {!canJoin &&
                                <Button variant="contained" onClick={onWithdrawGroupPairing} autoFocus>
                                    Withdraw
                                </Button>
                            }
                        </div>)
                    }
                    {canPair &&
                        <div style={inlineBlockStyle}>
                            <Tooltip title="By host only">
                                <span>
                                    <Button variant="contained" onClick={onPostConfirmGroupPairing} autoFocus
                                        disabled={!canConfirm}>
                                        Confirm
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}
