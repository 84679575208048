import ChatMessageList from "./chatMessage/chatMessageList";
import ChatMessageInput from "./chatMessage/chatMessageInput";
import { Box } from "@mui/material";

export default function Mainbar(props) {
  const {
    currentChannel,
    chatMessages,
    myProfile,
    onReplyInvitation,
    sendChatMessage,
  } = props;

  return (
    <Box>
      <ChatMessageList
        chatMessages={chatMessages}
        myProfile={myProfile}
        objectProfile={props.objectProfile}
        onReplyInvitation={onReplyInvitation}
      />
      {currentChannel !== -1 && <ChatMessageInput onSubmit={sendChatMessage} />}
    </Box>
  );
}
