const {
    formatAPIPath,
    getRequest,
} = require("./request.handler");

const postLogin = async (username, password) => {
    const directory = `/login/react?username=${username}&password=${password}`;
    const apiPath = formatAPIPath(directory);
    const result = await getRequest(apiPath)

    return result;
};

const postLoginWithCookie = async () => {
    const directory = "/login/react";
    const apiPath = formatAPIPath(directory);
    const result = await getRequest(apiPath)

    return result;
};

module.exports = { postLogin,postLoginWithCookie };
