import { Box, Grid, Typography, Tooltip } from "@mui/material";
import ChatroomProfileMenu from "./chatroomProfileMenu";
import InvitationPrivateDialog from "./invitationPrivateDialog";
import InvitationGroupDialog from "./invitationGroupDialog";
import UserProfile from "./userProfile";

export default function ChatRoomTopProfile(props) {
    // This is where the top bar in a chatroom is displayed
    const { profiles, channelInfo } = props.info;
    const { profileName, presence, uid, imageKey, gender } = profiles[0];
    const { channel, channelType } = channelInfo;

    const getImageLink = (imageKey, gender) =>
        `https://homester.hk/assets/img/img.php?t=uicon&filename=${imageKey}&gender=${gender}`;

    const profilePath = `https://homester.hk/profile.html?id=${uid}`;
    const profileIconPath = getImageLink(imageKey, gender);

    const onlineGreenDot = presence ? "green" : "";
    const onlineGreenDotClassName = `media d-block d-flex text-left py-3 online-status ${onlineGreenDot}`;
    const sexText =
        gender === "M" ? "Male" : gender === "F" ? "Female" : "Unknown sex";

    // Here we fully understand the lexical definitions and differences between gender and sex,
    // but since sex is conventionally mistaken as gender in our codebase,
    // we then only display the correct term for users.

    const memberInfoList = profiles.map((profile) => ({
        profileName: profile.profileName,
        role: profile.role,
        catpion: "Last seen 49 minutes ago",
        imageLink: getImageLink(profile.imageKey, profile.gender),
    }));

    return (
        <div style={{ width: "100%" }}>
            <ul className="nav">
                {channel === -1 && (
                    <Typography color={"black"}>You are not in logged in.</Typography>
                )}
                {channel !== -1 && (
                    <div className={onlineGreenDotClassName} style={{ width: "100%" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItem: "center",
                                gap: "10px",
                                margin: "10px",
                            }}
                        >
                            <Box>
                                <Tooltip title={sexText}>
                                    <img
                                        className="mr-3 rounded-circle user-icon"
                                        // className="img-fluid mr-3 rounded-circle"
                                        alt="profile-icon"
                                        width="50"
                                        //height="50"
                                        //style={{ borderRadius: "50%" }}
                                        src={profileIconPath}
                                    />
                                </Tooltip>
                            </Box>
                            <Box>
                                {channelType === "group" && (
                                    <div>
                                        <ChatroomProfileMenu
                                            profileName={`Group ${channel}`}
                                            memberInfoList={memberInfoList}
                                        />
                                        <Typography variant="caption" sx={{ color: "black" }}>
                                            {`${profiles.length} members`}
                                        </Typography>
                                    </div>
                                )}
                                {channelType === "private" && (
                                    <div>
                                        <UserProfile
                                            profileName={profileName}
                                            profileLink={profilePath}
                                            imageLink={profileIconPath}
                                        />
                                        <Typography variant="caption" sx={{ color: "black" }}>
                                            {presence ? "Online" : "Offline"}
                                        </Typography>
                                    </div>
                                )}
                            </Box>
                            <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                                <div>
                                    {channelType === "private" && (
                                        <InvitationPrivateDialog
                                            onSubmit={props.onPostInvitation}
                                        />
                                    )}
                                    {channelType === "group" && (
                                        <InvitationGroupDialog
                                            token={props.token}
                                            channel={channel}
                                            onSubmit={props.onPostInvitation}
                                        />
                                    )}
                                </div>
                            </Box>
                        </Box>
                        {/* <Grid container spacing={2}>
              <Grid item xs={1}>
                <Tooltip title={sexText}>
                  <img
                    className="mr-3 rounded-circle user-icon"
                    // className="img-fluid mr-3 rounded-circle"
                    alt="profile-icon"
                    width="50"
                    //height="50"
                    //style={{ borderRadius: "50%" }}
                    src={profileIconPath}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                {channelType === "group" && (
                  <div>
                    <ChatroomProfileMenu
                      profileName={`Group ${channel}`}
                      memberInfoList={memberInfoList}
                    />
                    <Typography variant="caption" sx={{ color: "black" }}>
                      {`${profiles.length} members`}
                    </Typography>
                  </div>
                )}
                {channelType === "private" && (
                  <div>
                    <UserProfile
                      profileName={profileName}
                      profileLink={profilePath}
                      imageLink={profileIconPath}
                    />
                    <Typography variant="caption" sx={{ color: "black" }}>
                      {presence ? "Online" : "Offline"}
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item xs={1}>
                <div>
                  {channelType === "private" && (
                    <InvitationPrivateDialog
                      onSubmit={props.onPostInvitation}
                    />
                  )}
                  {channelType === "group" && (
                    <InvitationGroupDialog
                      token={props.token}
                      channel={channel}
                      onSubmit={props.onPostInvitation}
                    />
                  )}
                </div>
              </Grid>
            </Grid> */}

                        <div className="media-body align-self-center mt-0 d-flex"></div>
                    </div>
                )}
            </ul>
        </div>
    );
}
