import { Box, Tooltip, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatListPrivate from "./chatList/chatListPrivate";
import LoginForm from "./login/loginForm";

export default function Sidebar(props) {
  const {
    collapse,
    currentChannel,
    chatList,
    myProfile,
    onClickChatListItem,
    sendLoginRequest,
  } = props;

  const className = collapse ? "" : "sidebar";
  const style = collapse
    ? {
      backgroundColor: "#EBEBEB",
      width: "100%",
      height: "100%",
    }
    : { backgroundColor: "#EBEBEB", width: "100%", height: "100%" };

  // const sortChatList = () => {
  //   const newList = chatList.sort((a, b) => {
  //     const aDate = new Date(a.channelInfo.lastMessage.datetime);
  //     const bDate = new Date(b.channelInfo.lastMessage.datetime);
  //     if (aDate > bDate) {
  //       return -1;
  //     } else if (aDate < bDate) {
  //       return 1;
  //     } else return 0;
  //   })
  //   newList[0].
  //   return newList
  // }

  return (
    <Box className={className} sx={style}>
      {!collapse && (
        <div className="logo">
          <Box style={{ display: "inline-block" }}>
            <Typography variant="h6" style={{ color: "#3c5666" }}>
              Homester
            </Typography>
          </Box>
          <Box style={{ float: "right" }}>
            <Tooltip title="Back to matching pool">
              <a href="https://homester.hk/matching.html">
                <LogoutIcon />
              </a>
            </Tooltip>
          </Box>
        </div>
      )}
      <div>
        <ul className="nav">
          <Box className="nav-item">
            <a className="nav-link active border-0" href="#">
              <i>
                <ChatBubbleOutlineIcon />
              </i>
              <br />
              <Typography variant="button">Chat</Typography>
            </a>
          </Box>
        </ul>
        <ChatListPrivate
          currentChannel={currentChannel}
          chatList={chatList}
          myProfile={myProfile}
          onClickItem={onClickChatListItem}
        />
        {/* hide the login form which is for test */}
        {/* <LoginForm onSubmit={sendLoginRequest} /> */}
        {/* placeholder for spacing */}
        {/* <Box sx={{ p: 2, height: "100px" }}>
          <input
            type="text"
            className="form-control"
            placeholder=""
            style={{ display: "none" }}
          />
        </Box> */}
      </div>
    </Box>
  );
}
